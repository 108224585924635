<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      hide-default-footer
    >
      <template v-slot:item.value="{ item }">
        <v-text-field
          hide-details
          dense
          outlined
          v-model="item.value"
          clearable
          @input="item_change(item)"
        ></v-text-field>
      </template>
    </v-data-table>
    <div class="d-flex justify-end pa-4" outlined>
      <v-btn color="default" v-on:click="cancel_click">{{
        $t("cancel")
      }}</v-btn>
      <v-btn
        class="ml-4"
        color="primary"
        v-on:click="save_click"
        :disabled="save_disabled"
        >{{ $t("save") }}</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import { GsDataTable, GsDataFilter } from "ngt-frontend-core";
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsInlineAttributesEditor",
  components: {
    GsDataTable
  },
  props: {
    entityId: {
      type: String,
      default: null
    },
    entityName: {
      type: String,
      default: null
    },
    collection: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      appSettingKey: "resourceServerDetailsAttrEditor",
      fullEndpoint: "",
      search: "",
      dialogEditorVisible: false,
      selectedLabelId: null,
      hasChange: false
    };
  },
  computed: {
    loadParams() {
      return {
        entityId: this.entityId,
        entityName: this.entityName,
        collection: this.collection
      };
    },
    headers() {
      return [
        {
          value: "name",
          text: this.$t("name"),
          align: "left",
          sortable: false,
          width: "40%"
        },
        {
          value: "value",
          text: this.$t("value"),
          align: "left",
          sortable: false,
          width: "60%"
        }
      ];
    },
    items() {
      if (this.$store.state.entityAttrs.items.length === 0) return [];
      return this.$store.state.entityAttrs.items;
    },
    save_disabled() {
      return !this.hasChange;
    }
  },
  async created() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("entityAttrs/load", this.loadParams);
      } catch (error) {
        this.error = "Error";
      } finally {
        this.isLoading = false;
      }
    },
    async cancel_click() {
      await this.loadData();
    },
    async save_click() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("entityAttrs/save", this.loadParams);
      } catch (error) {
        this.error = "Error";
      } finally {
        this.isLoading = false;
      }
    },
    item_change(item) {
      this.hasChange = true;
    }
  }
};
</script>
