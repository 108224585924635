<template>
  <v-container fluid class="pa-8">
    <v-row class="pb-4">
      <GsNavButtons />
    </v-row>
    <v-row>
      <v-container fluid class="ma-0 pa-0">
        <GsInlineAttributesEditor
          v-if="entityName"
          :entityId="entityId"
          :entityName="entityName"
          :collection="collection"
        />
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsNavButtons from "@/components/GsNavButtons";
import GsInlineAttributesEditor from "@/components/AttributeEditor/GsInlineAttributesEditor";

export default {
  name: "EntityAttributes",
  components: {
    GsNavButtons,
    GsInlineAttributesEditor
  },
  data() {
    return {
      entityName: null,
      entityId: null
    };
  },
  computed: {
    collection() {
      switch (this.entityName) {
        case "identities":
          return "identity";
        case "data-providers":
          return "dataProvider";
        case "clients":
          return "client";
        case "resource-servers":
          return "resourceServer";
        case "data-stores":
          return "dataStore";
      }
      return null;
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(v) {
        const p = this.$route.path.split("/");
        this.entityName = p[1];
        this.entityId = p[2];
        // console.log(`${this.entityName} ${this.entityId}`);
        this.init();
      }
    }
  },
  methods: {
    async init() {
      await this.setBreadCrumbs();
    }
  }
};
</script>
